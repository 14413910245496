<template>
  <div>
    <component
      :is="componentsName[info.chain_type]"
      :info="info"
      :hash="hash"
      :isMB="isMB"
    />
    <!-- <WechatPage v-if="info.chain_type == 1" :info="info" :isMB="isMB"/>
    <AppletsPage v-if="info.chain_type == 2" :info="info" :isMB="isMB"/>
    <WechatCardPage v-if="info.chain_type == 5" :info="info" :isMB="isMB"/>
    <DouYinCardPage v-if="info.chain_type == 3" :info="info" :isMB="isMB"/>
    <CodePage v-if="info.chain_type==6||info.chain_type==7||info.chain_type==9" :info="info" :isMB="isMB"/> -->
  </div>
</template>
<script>
import { initInfo } from '@/api/index'
import WechatPage from '@/components/WechatPage'
import AppletsPage from '@/components/AppletsPage'
import WechatCardPage from '@/components/WechatCardPage'
import DouYinCardPage from '@/components/DouYinCardPage'
import CodePage from '@/components/CodePage'
import DivideCodePage from '@/components/DivideCodePage'
import ImagesCodePage from '@/components/ImagesCodePage'

import isMobile from 'ismobilejs'
export default {
  components: {
    WechatPage,
    AppletsPage,
    WechatCardPage,
    DouYinCardPage,
    DivideCodePage,
    CodePage,
    ImagesCodePage,
  },
  data() {
    return {
      componentsName: {
        1: 'WechatPage',
        2: 'AppletsPage',
        3: 'DouYinCardPage',
        5: 'WechatCardPage',
        6: 'CodePage',
        7: 'CodePage',
        9: 'CodePage',
        10: 'DivideCodePage',
        11: 'ImagesCodePage',
        13: 'CodePage',
      },
      isMB: false,
      info: {},
      hash: {}
    }
  },
  created() {
    this.adaptDevice()
    this.getInfo()
  },
  computed: {},
  methods: {
    //判断是否移动端
    adaptDevice() {
      var isMbObj = isMobile()
      this.isMB = isMbObj.phone || isMbObj.tablet
    },
    getInfo() {
      var channel_id = this.$route.query.channel_id
      var path = window.location.pathname
      // 获取最后一级路径
      var lastSegment = path.substring(path.lastIndexOf('/') + 1)
      const getObjParam = (url) => {
        let obj = {}
        const arr = url.split('?')[1].split('&')
        arr.forEach((v) => (obj[v.split('=')[0]] = v.split('=')[1]))
        return obj
      }
      this.hash = (location.search && getObjParam(location.href)) || {}
      if (lastSegment === 'divideScanCode') {
        this.$router.push({
          name:'DivideScanCode',
          query:this.$route.query
        })
      } else if (lastSegment === '404') {
        this.$router.push({
          name:'404',
        })
      } else {
        var metaDescription = document.querySelector('meta[name="referrer"]');
        metaDescription.setAttribute('content', 'origin');
        console.log('document.referrer', document.referrer);
        const referrer = document.referrer || ''
        initInfo({ chain_code: lastSegment, channel_id, referrer, ...this.hash })
          .then((res) => {
            console.log('this.info',res.data);
            this.info = res.data
            const chain_type = res.data.chain_type
            if (
              chain_type == 0 ||
              chain_type == 4 ||
              chain_type == 8 ||
              chain_type == 12
            ) {
              metaDescription.setAttribute('content', 'never');
              window.location.href = res.data.url
            } else {
            }
          })
          .catch((err) => {
            const errMsg = err.message
            this.$router.push({name: '404',params:{errMsg}})
          })
          .finally(()=>{
            metaDescription.setAttribute('content', 'never');
          })
      }
    },
  },
}
</script>
<style lang="less" scoped></style>
